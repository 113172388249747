<template>
  <div class="mt-1">
    <div class="parent">
      <iframe allow="fullscreen" v-show="isLoaded" class="iframe-container" v-bind:src="launchURL" allowfullscreen webkitallowfullscreen></iframe>
    </div>
  </div>
</template>
<script>

import pramatic from "@/services/pragmatic";

export default {
  name: "CasinoGames",
  components: {
  },
  data: function () {
    return {
      loading: "",
      gameName: "",
      gameID: '',
      icon: "",
      isLoaded: false,
      launchURL: "",
      launchParameters: {},
      launchType: 1,
      isDemo: 0,
    };

  },
  mounted() {

    this.$store.dispatch("setCurrentPage", "casinogames");
    this.reloadProfile();

  },
  computed: {

    app: function () {
      return this.$store.state.app;
    },


    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },
  watch: {
    $route: {
      immediate: true,
      handler(to) {
        // react to route changes...
        this.gameName = to.params.gameName;
        this.gameID = to.params.gameID;
        this.launchParameters = to.params.launchParameters;
        this.launchType = to.params.launchType;
        this.isDemo = to.params.IsDemo;

        console.log('got launchType '+parseInt(this.launchType))
        console.log('got isDemo '+parseInt(this.isDemo))
        console.log('got isDemo '+parseInt(this.isDemo))

        if(parseInt(this.isDemo) === 1) {

          this.isLoaded = true;
          console.log(this.gameName+' ==> '+to.params.launchURL);

          if(parseInt(this.launchType) === 3 ) {

            window.open(to.params.launchURL)

          }
        }

        else if (parseInt(this.launchType) === 2  ) {

          this.isLoaded = false;
          this.getPragmaticPlayGameURL(this.gameID);

        } else if(parseInt(this.launchType) === 3) {

          this.isLoaded = false;
          this.getPragmaticPlayGameURL(this.gameID);

        } else if(parseInt(this.launchType) === 5) {

          //this.launchURL = to.params.launchURL;
          this.isLoaded = false;
          window.open(to.params.launchURL)

        } else {

          this.launchURL = to.params.launchURL;
          this.isLoaded = true;
          console.log(this.gameName+' ==> '+this.launchURL);

        }

      },
    },
  },
  methods: {

    getPragmaticPlayGameURL: function (gameID) {

      console.log('getPragmaticPlayGameURL | '+gameID)

      if(gameID === undefined || gameID.length === 0 ) {

        this.goTo('casino')
        //this.$router.push({ name: 'Pragmatic', params: { } });
        return
      }

      var profile = this.getProfile();

      var tokn = profile.t1;

      if(tokn === undefined) {

        // this.goTo('casino')
        this.$router.push({ name: 'casino', params: { } });
        this.$router.push({ name: 'login', params: { } });
        return ;
      }

      console.log("Here is the tokennnnnnnnn", tokn)

      let str = tokn;
      let result = str.replace(/=/g, '');

      console.log(result);

      var path = process.env.VUE_APP_CASINO_GET_GAME_URL;
      var accountId = process.env.VUE_APP_CASINO_ACCOUNT_ID;

      var data = {
        symbol: gameID,
        token: accountId+''+result
      }

      console.log("Here is the Data", data)

      var vm = this;
      vm.loading = 'loading';
      vm.isLoaded = false;

      pramatic.post(path, JSON.stringify(data))
          .then(res => {


            vm.loading = 'loading';
            vm.isLoaded = true;
            var dat = res.data.message.gameURL;

            dat = dat.replaceAll("maybets.com","jetpesa.com")

            vm.launchURL = dat;

            if( parseInt(this.launchType) ===3) {

              window.open(this.launchURL)


            }

            console.log("Here is the launch_url=====================" + JSON.stringify(dat))

          })
          .catch(err => {

            vm.loading = '';

            vm.loading = '';

            if (err.response) {

              vm.setError("Failed", err.response.data.message)
              console.log(JSON.stringify(err.response.data.message.gameURL));

            } else if (err.request) {

              console.log(JSON.stringify(err.request));

            } else {

              console.log(JSON.stringify(err));

            }

          })
    },

    goToCasino: function () {

      this.$router.push({ name: "casino", params: {} });

    },

    showDeposit: function () {

      document.getElementById("deposit-init").click();

    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },

  },
};
</script>

<style scoped>
.title_name {
  color: #fff;
}
.parent {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}
.parent .banner {
  width: 100%;
  /* height: 48px; */
}
.parent iframe {
  background: #000;
  border: none;
  flex-grow: 1;
}
h4 {
  position: relative;
  top: 0.6em;
  right: 1em;
}
</style>