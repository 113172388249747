<template>
  <div class="wrapper">

    <div class="body" style="padding:0;">

      <Slider></Slider>

      <div class="cas-header-wrapper feature-stick">

        <div class="se-wrapper py-2 px-2">

          <div class="input-group mb-0">

            <input @keyup="keyUpSearch" @keyup.enter="performSearch" type="text" class="form-control" id="search-input"
                   name="keyword" placeholder="Search for casino games" aria-label="Search for games, leagues, events"
                   v-model="to_search" style="background-color:white; color: black; font-size:unset;">
            <!-- <div class="input-group-append">
              <span class="input-group-text"></span>
            </div> -->
            <div class="input-group-append cursor-pointer" @click="performSearch">
              <span class="input-group-text text-light"
                    style="background-color: var(--lighestgray1); font-size: unset;"><i class="bi bi-search mr-1"></i> Search </span>
            </div>
          </div>
        </div>

        <div class="catsec pb-3 pt-1" id="catsec">
        <span class="cattit" @click="getCasinoGames(item)" :class="isActive(item)" v-bind:key="getKey(item)"
              v-for="item in casinocategoriesObj">
            {{ item.title }}
        </span>
        </div>
      </div>
      <ShimmerCasino v-if="loading"></ShimmerCasino>

      <div class="row m-0 p-0" style="background-color: #121D2C">

        <div v-show="searchgamesdata.length > 0" class="casino-headers " style="">
          <span>Search Results</span>
        </div>

        <!-- new card design -->
        <div v-show="searchgamesdata.length > 0" style="" class="row m-0 p-0 casino-row">

          <div class="card casino-card col-lg-2 col-md-2 col-sm-2 col-4 m-0 p-0" data-toggle="modal"
               :data-target="'#'+o.game_id" v-for="(o, index) in searchgamesdata" :key="getKey(index)">

            <a>
              <img
                  :src="getImg(o.image)"
                  alt="Avatar"
                  style="
                  max-width: 100%;
                  height: 140px;
                  border-radius: 5px;border:0px;"
              />
            </a>

            <!-- Modal -->
            <div class="modal fade" :id="o.game_id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">

              <div class="modal-dialog modal-dialog-centered" role="document">

                <div class="modal-content" style="background-color: #353b40">

                  <div class="modal-body">

                    <div style="background-color: #353b40">

                      <div class="d-flex">
                        <a title="Close" class="modal-close" @click="closeModal">X</a>
                        <h6 class="text-light">{{ o.game_name }}</h6>
                      </div>

                      <img :src="o.image" alt="gameimg" class="ww-100 mb-2"/>

                      <div class="text-center ">

<!--                        <router-link class="btn btn-warning ww-100 mb-2 fun-mode" v-show="parseInt(o.has_demo) === 1" :to="{name: 'casinogames', params: {IsDemo:1, gameName: o.game_name,gameID: o.game_id,launchURL: o.demo_url,launchType: o.launch_type,launchParameters: o.launch_parameters},}">
                          Fun Mode
                        </router-link>-->

                        <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'casinogames', params: {IsDemo:1, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: o.launch_type,launchParameters: o.launch_parameters},}">
                          Play Now
                        </router-link>

                        <!--
                        <router-link v-show="parseInt(o.has_demo) === 1"
                                     :to="{name: 'casinogames',params: {gameName: o.game_name,launchURL: o.demo_url,launchType: 1,gameID: o.game_id},}">
                          <button class="btn btn-warning ww-100 mb-2"><b>DEMO</b></button>
                        </router-link>

                        <a :href="getLaunchURL(o.launch_url)" target="_blank" class="btn btn-success ww-100 mb-2">
                          PLAY NOW
                        </a>
                        -->

                      </div>

                    </div>

                  </div>

                </div>

              </div>

            </div>

          </div>

        </div>

        <!-- new card design -->
      </div>

      <div id="scroller">

        <!--      loop through to display games on their own card-->
        <div :id="i.category_name" v-show="searchgamesdata.length == 0" class="row m-0 p-0 pb-5"
             v-for="(i, x) in allgamesdata" :key="getKey(x)" style="background-color: #121D2C">

          <div v-show="i.category_id !== 24 && i.category_id !== 15 " class="casino-headers d-none " style="">
            <span v-text="i.category_name"></span>
          </div>

          <!-- new card design -->
          <div v-show="i.category_id !== 24 && i.category_id !== 15 " style="" class="row m-0 p-0 casino-row">

            <div class="card casino-card col-lg-2 col-md-2 col-sm-2 col-4 m-0 p-0" data-toggle="modal"
                 :data-target="'#'+o.game_id" v-for="(o, index) in i.games" :key="getKey(index)">

              <a>
                <img
                    :src="getImg(o.image)"
                    alt="Avatar"
                    style="
                  max-width: 100%;
                  height: 140px;
                  border-radius: 5px;border:0px;

                "
                />
              </a>

              <!-- Modal -->
              <div class="modal " :id="o.game_id" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
                   aria-hidden="true">

                <div class="modal-dialog modal-dialog-centered" role="document">

                  <div class="modal-content" style="background-color: #353b40">

                    <div class="modal-body">

                      <div style="background-color: #353b40">

                        <div class="d-flex">
                          <a title="Close" class="modal-close" @click="closeModal">X</a>

                          <h6 class="text-light">{{ o.game_name }}</h6>
                        </div>

                        <img :src="o.image" alt="gameimg" class="ww-100 mb-2"/>

                        <div class="text-center " v-show="i.category_id !== 24 && i.category_id !== 15 ">

<!--                          <router-link class="btn btn-warning ww-100 mb-2 fun-mode" v-show="parseInt(o.has_demo) === 1" :to="{name: 'casinogames', params: {IsDemo:1, gameName: o.game_name,gameID: o.game_id,launchURL: o.demo_url,launchType: o.launch_type,launchParameters: o.launch_parameters},}">
                            Fun Mode
                          </router-link>-->

                          <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'casinogames', params: {IsDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: o.launch_type,launchParameters: o.launch_parameters},}">
                            Play Now
                          </router-link>

                        </div>

                        <div class="text-center " v-show="i.category_id === 24 || i.category_id === 15 ">

<!--                          <router-link class="btn btn-warning ww-100 mb-2 fun-mode" v-show="parseInt(o.has_demo) === 1" :to="{name: 'casinogames', params: {IsDemo:1, gameName: o.game_name,gameID: o.game_id,launchURL: o.demo_url,launchType: 3,launchParameters: o.launch_parameters},}">
                            Fun Mode
                          </router-link>-->

                          <router-link class="btn btn-warning ww-100 mb-2" :to="{name: 'casinogames', params: {IsDemo:0, gameName: o.game_name,gameID: o.game_id,launchURL: o.launch_url,launchType: 3,launchParameters: o.launch_parameters},}">
                            Play Now
                          </router-link>

                        </div>

                      </div>

                    </div>

                  </div>

                </div>
              </div>

            </div>

          </div>

          <!-- new card design -->
        </div>

      </div>

    </div>
  </div>
</template>
<style scoped>
.card {
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  /* width: 40%; */
  border-radius: 5px;
  margin-right: 5px;
  margin-bottom: 5px;
  display: inline-block;
}

.card:hover {
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2);
}

.container {
  padding: 2px 16px;
}

.modal-window {
  position: fixed;
  background-color: rgba(255, 255, 255, 0.25);
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 99999;
  visibility: hidden;
  opacity: 0;
  pointer-events: none;
  transition: all 0.3s;
}

.modal-window:target {
  visibility: visible;
  opacity: 1;
  pointer-events: auto;
}

.modal-window > div {
  width: 400px;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  padding: 2em;
  background: white;
}

.modal-window header {
  font-weight: bold;
}

.modal-window h1 {
  font-size: 150%;
  margin: 0 0 15px;
}

.modal-close {
  color: #aaa;
  line-height: 50px;
  font-size: 80%;
  position: absolute;
  right: 0;
  text-align: center;
  top: 0;
  width: 70px;
  text-decoration: none;
}

.modal-close:hover {
  color: black;
}

.container {
  display: grid;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

.modal-window > div {
  border-radius: 5px;
}

/* .modal-window div:not(:last-of-type) {
  margin-bottom: 15px;
} */
</style>
<script>
import Vue from 'vue'
import axios from "@/services/api";
import ShimmerCasino from "../shimmer/ShimmerCasino.vue";
import ScrollLoader from 'vue-scroll-loader'

const Slider = () => import('../SliderCasino.vue');

Vue.use(ScrollLoader)
export default {
  name: "Casino",
  components: {
    Slider,
    ShimmerCasino
  },
  data: function () {
    return {
      match_id: 0,
      game_id: 0,
      fixture: {},
      initial_fixture: {},
      market_group: {
        id: 0,
      },
      totals: [],
      live: false,
      seconds: 0,
      possible_win: "",
      payout: "",
      tax: "",
      odd_id: "",
      outcome: {},
      msisdn: "",
      password: "",
      code: "",
      action_verify_password: false,
      action_success: false,
      message: "",
      allgamesdata: [],
      to_search: '',
      searchgamesdata: [],
      allgames: [],
      casinocategories: [9, 4, 7, 12, 18, 17, 16],
      casinocategoriesObj: [
        {
          "id": 9,
          "title": "Popular"
        },
        {
          "id": 4,
          "title": "Slots"
        },
        // {
        //   "id" : 16,
        //   "title" : "Video Slots"
        // },
        // {
        //   "id" : 12,
        //   "title" : "Jackpot Games"
        // },
        {
          "id": 17,
          "title": "Classic Casino"
        },
        {
          "id": 8,
          "title": "Table Games"
        },
        // {
        //   "id" : 7,
        //   "title" : "Poker"
        // },
      ],
      casinoCategory: 9,
      casinoCategoryActive: {
        "id": 9,
        "title": "Popular"
      },
      casinoCategoryIndex: 0,
      disable: true,
      loading: false,
      touchstartX: 0,
      touchstartY: 0,
      touchendX: 0,
      touchendY: 0
    };
  },

  mounted: function () {

    this.$store.dispatch("setCurrentPage", "casino");

    this.getCasinoGames(this.casinoCategoryActive);

    this.reloadProfile();

    var categories = [];

    var vm = this;

    this.jQuery.each(vm.games, function (k, v) {
      var key = v.Category.replaceAll(/ /g, "");
      var exists = false;

      // lets check if category exists
      vm.jQuery.each(categories, function (i, x) {

        if (x.key == key) {

          x.games.push(v);
          exists = true;
        }

        categories[i] = x;
      });

      if (!exists) {

        var gm = [];
        gm.push(v);

        var cat = {
          name: v.Category,
          key: key,
          games: gm,
        };

        categories.push(cat);
      }
    });

    this.groupedGames = categories;

    this.showSlides();
    var touchableElement = document.getElementById('scroller');
    touchableElement.addEventListener('touchstart', function (event) {
      vm.touchstartX = event.changedTouches[0].screenX;
      vm.touchstartY = event.changedTouches[0].screenY;
    }, false);

    touchableElement.addEventListener('touchend', function (event) {
      vm.touchendX = event.changedTouches[0].screenX;
      vm.touchendY = event.changedTouches[0].screenY;
      vm.handleGesture();
    }, false);


  },

  computed: {
    app: function () {
      return this.$store.state.app;
    },

    profile: function () {
      return this.getProfile();
    },
    token: function () {
      return this.getAuth();
    },
  },

  methods: {
    handleGesture: function () {
      var scrollLeft = document.getElementById('catsec').scrollLeft
      if (this.touchendX < this.touchstartX && (this.touchstartX - this.touchendX) > 125) {
        console.log('Swiped Left');
        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        let count = this.casinocategoriesObj.length;
        let newIndex = 0
        if (index < 0) {
          newIndex = 1;
        } else {
          newIndex = index + 1;
        }
        console.log("Old index: " + index)
        if (newIndex <= count) {
          this.casinoCategoryActive = this.casinocategoriesObj[newIndex];
          this.getCasinoGames(this.casinoCategoryActive);

          document.getElementById('catsec').scrollLeft = scrollLeft + 50;
        }
      }

      if (this.touchendX > this.touchstartX && (this.touchendX - this.touchstartX) > 125) {
        console.log('Swiped Right');

        console.log("start: " + this.touchstartX + "end: " + this.touchendX)
        let index = this.casinocategoriesObj.indexOf(this.casinoCategoryActive);
        // let count = this.casinocategoriesObj.length;
        if ((index - 1) > -1) {
          this.casinoCategoryActive = this.casinocategoriesObj[(index - 1)];
          this.getCasinoGames(this.casinoCategoryActive);
          document.getElementById('catsec').scrollLeft = scrollLeft - 50;
        }

      }

      if (this.touchendY < this.touchstartY) {
        console.log('Swiped Up');
      }

      if (this.touchendY > this.touchstartY) {
        console.log('Swiped Down');
      }

      if (this.touchendY === this.touchstartY) {
        console.log('Tap');
      }
    },
    isActive: function (item) {
      // console.log(item)
      if (item.id == this.casinoCategoryActive.id) {
        return ' active';
      } else {
        return '';
      }
    },
    closeModal: function () {
      var docs = document.getElementsByClassName("modal-window")[0];
      docs.classList.add('d-none');
    },
    performSearch: function () {
      var keyword = this.to_search;
      var games = this.allgames;
      console.log(keyword);
      var results = games.filter(function (el) {
        // console.log(el);
        var game_name = el.game_name.toLowerCase();
        return game_name.match(keyword.toLowerCase()) == null ? false : game_name.match(keyword.toLowerCase()).length > 0;
      });
      if (results.length > 0 && keyword !== "") {

        this.searchgamesdata = results;
      } else {
        this.searchgamesdata = [];
      }

    },
    keyUpSearch: function () {
      if (this.to_search == '') {
        this.searchgamesdata = [];
      }
    },
    loadPage: function (launch_url) {

      window.open(launch_url, '_blank');

    },

    getImg: function (img) {

      return img.replaceAll("/rec/325/", "/square/200/")

    },

    showSlides: function () {

      var vm = this;

      var slideIndex = 0;

      var i;
      var slides = document.getElementsByClassName("mySlides");
      var dots = document.getElementsByClassName("dot");

      for (i = 0; i < slides.length; i++) {

        slides[i].style.display = "none";
      }

      slideIndex++;

      if (slideIndex > slides.length) {

        slideIndex = 1;
      }

      for (i = 0; i < dots.length; i++) {

        dots[i].className = dots[i].className.replace(" active", "");

      }

      if (slides[slideIndex - 1]) {

        slides[slideIndex - 1].style.display = "block";
        dots[slideIndex - 1].className += " active";

      }

      setTimeout(function () {

        vm.showSlides();

      }, 2000)  // Change image every 2 seconds

    },

    getLaunchURL: function (launch_url) {

      var profile = this.getProfile();
      var tokn = profile.t1;

      // console.log('GOT launch_url '+launch_url);
      // console.log('GOT tokn '+tokn);

      launch_url = launch_url.replaceAll("profile_token", tokn);
      // console.log('GOT tokenized launch_url '+launch_url);

      return launch_url;

    },

    getKey: function (index) {

      return Math.random()
          .toString(10)
          .replace("0.", "games-id-" + index + "-");
    },

    getCasinoGames: function (activeCategory) {
      this.loading = true;
      var platform = this.isMobile() ? 'MOBILE' : 'WEB';
      var path = process.env.VUE_APP_CASINO_GAMES_URL;
      // var category_id = this.casinocategories[this.casinoCategoryIndex];
      this.casinoCategoryActive = activeCategory;
      path = path.replace("{category_id}", activeCategory.id);
      path = path.replace("{provider_id}", 0);
      path = path.replace("{platform}", platform);

      const passtoken = {};
      var vm = this;
      vm.allgames = [];
      vm.allgamesdata = [];
      console.log("index: " + activeCategory.id);
      axios
          .post(path, passtoken)
          .then((resp) => {
            var allgames = resp.data.message;
            // vm.allgamesdata = vm.allgamesdata.concat(allgames);
            vm.allgamesdata = allgames;
            // console.log(allgames);
            vm.jQuery.each(allgames, function (i, x) {
              // x.games.push(v);
              vm.allgames = x.games;
            });

            window.scrollTo(0, 0);
            // this.casinoCategoryIndex = this.casinoCategoryIndex+1;
            // if((this.casinoCategoryIndex) >= this.casinocategories.length){
            //   this.disable = true;
            // }else{
            //   this.disable = false;
            // }
            this.loading = false;

          });
    },

  },
  filters: {

    amount: function (val) {

      if (val === undefined) {
        return val;
      }

      return parseFloat(val).toFixed(2).toLocaleString();
    },
  },
};
</script>